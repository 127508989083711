import { Field, Input, Label, Radio, RadioGroup } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";

import { Payment } from "@entities/payment";
import { usePlayerMoneyFormatter } from "@entities/player";
import { APIError, emitter } from "@shared/lib";
import { Button } from "@shared/ui";
import { Spin } from "@shared/ui/spin";

import type { TPaymentMethodsList } from "@shared/lib";
import type { ChangeEvent, ChangeEventHandler, SetStateAction } from "react";

export function DepositTab() {
  const { data, isLoading } = Payment.useMethodList("deposit");

  if (isLoading) {
    return <WalletTabSpinner />;
  }

  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  return <DepositTabContent data={data} />;
}

function DepositTabContent({ data }: { data: TPaymentMethodsList["data"] }) {
  const [paymentMethod, setPaymentMethod] = useState(data[0].id);
  const [paymentAmount, setPaymentAmount] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);

  const { $t } = useTranslate();

  const { mutate: deposit, isPending } = Payment.useDeposit();

  function penniesToDollars(amount = 0) {
    return amount / 100;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = formRef.current;

    if (!form) return;

    const data = new FormData(form);
    data.set("payment-amount", String(Number(data.get("payment-amount")) * 100));

    if (validateForm(data)) {
      deposit(
        {
          methodId: Number(data.get("payment-method")),
          amount: Number(data.get("payment-amount")),
        },
        {
          onSuccess: (data) => {
            if (data.error) {
              const { message, code } = data.error;
              emitter.emit("ERROR_MODAL_OPEN", new APIError(message, { code }));
            } else if (data.result.paymentUrl) {
              window.location.href = data.result.paymentUrl;
            }
          },
        },
      );
    } else {
      console.error("Form is invalid");
    }
  }

  function validatePaymentMethod(method: number) {
    return data.some((item) => item.id === method);
  }

  function validatePaymentAmount(amount: number, method: number) {
    const selectedMethod = data.find((item) => item.id === method);

    if (!selectedMethod) return false;

    const isDepositMethodWithLimits = Boolean(selectedMethod.limits);

    if (isDepositMethodWithLimits) {
      const min = selectedMethod.limits?.min ?? 0;
      const max = selectedMethod.limits?.max ?? 0;

      return amount && amount >= min && amount <= max;
    } else {
      return amount && amount > 0;
    }
  }

  function validateForm(data: FormData) {
    const paymentMethod = Number(data.get("payment-method"));
    const paymentAmount = Number(data.get("payment-amount"));

    return (
      validatePaymentMethod(paymentMethod) && validatePaymentAmount(paymentAmount, paymentMethod)
    );
  }

  const selectedMethod = data.find((item) => item.id === Number(paymentMethod));
  const isDepositMethodWithLimits = Boolean(selectedMethod?.limits);

  let paymentAmountMin;
  let paymentAmountMax;
  if (isDepositMethodWithLimits) {
    paymentAmountMin = penniesToDollars(selectedMethod?.limits?.min);
    paymentAmountMax = penniesToDollars(selectedMethod?.limits?.max);
  }

  useEffect(() => {
    const defaultAmount = selectedMethod?.limits?.default;
    if (defaultAmount !== undefined) {
      setPaymentAmount(String(penniesToDollars(defaultAmount)));
    }
  }, [paymentMethod]);

  return (
    <div className="lg:overflow-y-auto lg:h-full lg:max-h-full relative px-1 min-h-0 scrollbar-thin custom-scrollbar gutter-stable mobile-only:pb-5">
      <form
        className={`lg:text-sm text-xs ${isPending ? "blur-sm" : ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <ol className="list-decimal list-inside space-y-5">
          <li>
            <span>{$t({ defaultMessage: "Choose one of the payment methods" })}</span>
            <RadioGroup
              className="mt-3"
              value={paymentMethod}
              onChange={setPaymentMethod}
              name="payment-method"
            >
              <ul className="lg:grid-cols-3 landscape:grid-cols-3 grid grid-cols-2 gap-3">
                {data.map(({ id, name, logoUrl, limits }) => (
                  <li className="h-24" key={id}>
                    <PaymentMethodCard id={id} name={name} logoUrl={logoUrl} limits={limits} />
                  </li>
                ))}
              </ul>
            </RadioGroup>
          </li>
          <li>
            <span>{$t({ defaultMessage: "Enter the amount you want to deposit" })}</span>
            <Field className="mt-3" disabled={!selectedMethod}>
              <Label className="font-bold data-[disabled]:opacity-50">
                {$t({ defaultMessage: "Amount" })}:
              </Label>
              <div className="lg:gap-5 flex gap-3 mobile-only:flex-col">
                <Input
                  className="input appearance-none w-52 data-[disabled]:opacity-50"
                  placeholder={$t({ defaultMessage: "Enter the amount" })}
                  name="payment-amount"
                  type="number"
                  inputMode="numeric"
                  value={paymentAmount}
                  // onChange remove all non-numeric characters
                  pattern="[0-9]*"
                  onChange={(ev: { target: { value: SetStateAction<string> } }) =>
                    setPaymentAmount(ev.target.value)
                  }
                  min={paymentAmountMin}
                  max={paymentAmountMax}
                  step={paymentAmountMin}
                  required
                />
                <Button
                  className="mobile-only:w-fit mobile-only:mx-auto"
                  type="primary"
                  htmlType="submit"
                  disabled={!paymentAmount}
                >
                  {$t({ defaultMessage: "Deposit" })}
                </Button>
              </div>
            </Field>
            <div className="lg:text-sm text-xs text-blue-bayoux mt-3">
              {$t({
                defaultMessage:
                  "After clicking 'Deposit', you will be redirected to the payment page.",
              })}
            </div>
          </li>
        </ol>
      </form>

      <div className={`absolute inset-0 flex-center ${isPending ? "visible" : "invisible"}`}>
        <Spin className="lg:w-40 w-32" />
      </div>
    </div>
  );
}

export function WithdrawTab() {
  const { data, isLoading } = Payment.useMethodList("withdraw");

  if (isLoading) {
    return <WalletTabSpinner />;
  }

  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  return <WithdrawTabContent data={data} />;
}

function WithdrawTabContent({ data }: { data: TPaymentMethodsList["data"] }) {
  const [paymentMethod, setPaymentMethod] = useState(data[0].id);
  const [paymentAmount, setPaymentAmount] = useState<string>("");
  const [paymentAccount, setPaymentAccount] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);

  const { $t } = useTranslate();

  const { mutate: withdraw, isPending } = Payment.useWithdraw();

  function penniesToDollars(amount = 0) {
    return amount / 100;
  }

  function requestWithdraw(data: FormData) {
    withdraw(
      {
        methodId: Number(data.get("payment-method")),
        amount: Number(data.get("payment-amount")),
        account: data.get("payment-account") as string,
      },
      {
        onSuccess: (data) => {
          if (data.error) {
            const { message, code } = data.error;
            emitter.emit("ERROR_MODAL_OPEN", new APIError(message, { code }));
          } else {
            emitter.emit("SUCCESS_MODAL_OPEN", $t({ defaultMessage: "Withdrawal request sent" }));
          }
        },
      },
    );
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = formRef.current;

    if (!form) return;

    const data = new FormData(form);
    data.set("payment-amount", String(Number(data.get("payment-amount")) * 100));

    if (validateForm(data)) {
      requestWithdraw(data);
    } else {
      console.error("Form is invalid");
    }
  }

  function validatePaymentMethod(method: number) {
    return data.some((item) => item.id === method);
  }

  function validatePaymentAmount(amount: number, method: number) {
    const selectedMethod = data.find((item) => item.id === method);

    if (!selectedMethod) return false;

    const isDepositMethodWithLimits = Boolean(selectedMethod.limits);

    if (isDepositMethodWithLimits) {
      const min = selectedMethod.limits?.min ?? 0;
      const max = selectedMethod.limits?.max ?? 0;

      return amount && amount >= min && amount <= max;
    } else {
      return amount && amount > 0;
    }
  }

  function validatePaymentAccount(account: string) {
    return account.length > 0;
  }

  function validateForm(data: FormData) {
    const paymentMethod = Number(data.get("payment-method"));
    const paymentAmount = Number(data.get("payment-amount"));
    const paymentAccount = data.get("payment-account") as string;

    return (
      validatePaymentMethod(paymentMethod) &&
      validatePaymentAmount(paymentAmount, paymentMethod) &&
      validatePaymentAccount(paymentAccount)
    );
  }

  function handlePaymentAccountChange(ev: ChangeEvent<HTMLInputElement>) {
    setPaymentAccount(ev.target.value);
  }

  const selectedMethod = data.find((item) => item.id === Number(paymentMethod));
  const isDepositMethodWithLimits = Boolean(selectedMethod?.limits);

  let paymentAmountMin;
  let paymentAmountMax;
  if (isDepositMethodWithLimits) {
    paymentAmountMin = penniesToDollars(selectedMethod?.limits?.min);
    paymentAmountMax = penniesToDollars(selectedMethod?.limits?.max);
  }

  const PaymentAccountFieldComponent = selectPaymentAccountComponent(selectedMethod?.type);

  useEffect(() => {
    const defaultAmount = selectedMethod?.limits?.default;
    if (defaultAmount !== undefined) {
      setPaymentAmount(String(penniesToDollars(defaultAmount)));
    }
  }, [paymentMethod]);

  return (
    <div className="lg:overflow-y-auto lg:h-full lg:max-h-full relative px-1 min-h-0 scrollbar-thin custom-scrollbar gutter-stable mobile-only:pb-5">
      <form
        className={`lg:text-sm text-xs ${isPending ? "blur-sm" : ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <p>
          {$t({
            defaultMessage:
              "You can make a withdrawal request. To do this, specify the amount requested for withdrawal.",
          })}
        </p>
        <hr className="border-blue-bayoux my-3" />
        <ol className="list-decimal list-inside space-y-5">
          <li>
            <span>
              {$t({
                defaultMessage: "Choose one of the ways where you want to receive payment.",
              })}
            </span>
            <RadioGroup
              className="mt-3"
              value={paymentMethod}
              onChange={setPaymentMethod}
              name="payment-method"
            >
              <ul className="lg:grid-cols-3 landscape:grid-cols-3 grid grid-cols-2 gap-3">
                {data.map(({ id, name, logoUrl, commission, limits }) => (
                  <li className="h-28" key={id}>
                    <PaymentMethodCard
                      id={id}
                      name={name}
                      logoUrl={logoUrl}
                      commission={commission}
                      limits={limits}
                    />
                  </li>
                ))}
              </ul>
            </RadioGroup>
          </li>
          <li>
            <PaymentAccountFieldComponent
              value={paymentAccount}
              onChange={handlePaymentAccountChange}
              disabled={!selectedMethod}
            />
          </li>
          <li>
            <span>{$t({ defaultMessage: "Enter the amount you want to withdraw" })}</span>
            <Field className="mt-3" disabled={!selectedMethod}>
              <Label className="block font-bold data-[disabled]:opacity-50">
                {$t({ defaultMessage: "Amount" })}:
              </Label>
              <div className="lg:gap-5 flex gap-3 mobile-only:flex-col">
                <Input
                  className="input appearance-none w-56 data-[disabled]:opacity-50"
                  placeholder={$t({ defaultMessage: "Enter the amount" })}
                  name="payment-amount"
                  type="number"
                  value={paymentAmount}
                  pattern="[0-9]*"
                  onChange={(ev: { target: { value: SetStateAction<string> } }) =>
                    setPaymentAmount(ev.target.value)
                  }
                  min={paymentAmountMin}
                  max={paymentAmountMax}
                  step={paymentAmountMin}
                  required
                />
                <Button
                  className="mobile-only:w-fit mobile-only:mx-auto"
                  type="primary"
                  htmlType="submit"
                  disabled={!paymentAmount}
                >
                  {$t({ defaultMessage: "Request withdraw" })}
                </Button>
              </div>
            </Field>
            <div className="text-blue-bayoux text-sm mt-3"></div>
          </li>
        </ol>
      </form>

      <div className={`absolute inset-0 flex-center ${isPending ? "visible" : "invisible"}`}>
        <Spin className="lg:w-40 w-32" />
      </div>
    </div>
  );
}

function selectPaymentAccountComponent(type = "") {
  switch (type) {
    case "crypto":
      return CryptoWalletField;
    case "bank_account":
      return BankAccountField;
    case "card":
      return CardNumberField;
    case "phone":
      return PhoneNumberField;
    default:
      return BankAccountField;
  }
}

interface IPaymentAccountFieldProps {
  value: string | number;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

function BankAccountField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter bank account number" })}</span>
      <Field className="mt-3" disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Account number" })}:
        </Label>
        <Input
          className="input appearance-none w-80 data-[disabled]:opacity-50"
          placeholder={$t({ defaultMessage: "Bank account number" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

function CryptoWalletField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter crypto wallet address" })}</span>
      <Field className="mt-3" disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Crypto wallet address" })}:
        </Label>
        <Input
          className="input appearance-none w-80 data-[disabled]:opacity-50"
          placeholder={$t({ defaultMessage: "Crypto wallet address" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

function PhoneNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter phone number" })}</span>
      <Field className="mt-3" disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Phone number" })}:
        </Label>
        <Input
          className="input appearance-none w-80 data-[disabled]:opacity-50"
          placeholder={$t({ defaultMessage: "Phone number" })}
          name="payment-account"
          autoComplete="tel"
          type="phone"
          value={value}
          required
          pattern="[0-9]{6,100}"
          onChange={onChange}
        />
      </Field>
    </>
  );
}

function CardNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter card number" })}</span>
      <Field className="mt-3" disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Card number" })}:
        </Label>
        <Input
          className="input appearance-none w-80 data-[disabled]:opacity-50"
          placeholder="xxxx xxxx xxxx xxxx"
          name="payment-account"
          autoComplete="cc-number"
          inputMode="numeric"
          type="tel"
          pattern="[0-9\s]{13,19}"
          required
          maxLength={19}
          value={value}
          onChange={onChange}
        />
      </Field>
    </>
  );
}

function WalletTabSpinner() {
  return (
    <div className="relative flex-center select-none size-full">
      <Spin className="lg:w-40 w-32" />
    </div>
  );
}

interface IPaymentMethodCardProps {
  id: number;
  name: string;
  logoUrl: string | null;
  commission?: string | null;
  limits?: {
    min: number;
    max: number;
  };
}

function PaymentMethodCard({ id, name, logoUrl, commission, limits }: IPaymentMethodCardProps) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();
  return (
    <Radio className="payment-method-card" as="div" value={id}>
      <img
        className="h-13 object-contain"
        src={logoUrl ?? ""}
        alt={name}
        width="90%"
        height="100%"
      />
      {commission && Number(commission) > 0 && (
        <div className="lg:text-xs lg:leading-none rounded-rounded bg-yellow-orange p-1 font-bold text-center uppercase dark:text-black">
          {$t({ defaultMessage: `commission: {commission}%` }, { commission })}
        </div>
      )}
      {limits && (
        <div className="font-bold leading-none text-center">
          {formatMoney(limits?.min ?? 0, { style: "decimal" })}
          {" - "}
          {formatMoney(limits?.max ?? 0)}
        </div>
      )}
    </Radio>
  );
}
