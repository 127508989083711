import { Lobby } from "@lobby/core/entities/lobby";
import { useTranslate } from "@lobby/ocb-intl";
import { useModal } from "@shared/lib";
import { Modal } from "@shared/ui/modal/modal";

export function ContactsModal() {
  const { isOpen, close } = useModal("contacts");
  const { $t } = useTranslate();
  const lobby = Lobby.useLobby();
  const contacts = lobby.data?.contacts ?? "";

  if (!contacts) return null;

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <Modal.Panel className="top-0 max-h-[44.375rem] w-screen overflow-y-auto border-keppel bg-mercury p-5 lg:top-10 lg:w-[44.375rem] lg:rounded-rounded lg:border-1 dark:border-java1 dark:bg-ebony-clay ">
        <Modal.Title className="relative justify-center">
          {$t({
            defaultMessage: "Contacts",
          })}
          <Modal.CloseButton onClick={close} />
        </Modal.Title>
        <div className="pb-5">
          <div className="mt-5 flex flex-col gap-5 px-5">
            <p className="max-w-3xl whitespace-pre-wrap">{contacts}</p>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
